<template>
   <div class="container-fluid fixed-bottom">
      <div class="row justify-content-center">
         <div class="col-12 col-md-3 justify-content-center align-items-center align-self-center align-middle text-center">
            <div class="nav-dock shadow-lg d-flex">
               <!-- <div class="item justify-content-center">
                  <img src="../assets/images/ic_save.svg" class="btn-dock" alt="">
                  <p class="title">Simpan</p>
               </div> -->
               <div class="item justify-content-center">
                  <img src="../assets/images/ic_export.svg" class="btn-dock" alt="">
                  <p class="title">Export</p>
               </div>
               <div class="item justify-content-center">
                  <img src="../assets/images/ic_import.svg" class="btn-dock" alt="">
                  <p class="title">Import</p>
               </div>
               <div class="item justify-content-center">
                  <img src="../assets/images/ic_print.svg" class="btn-dock" alt="">
                  <p class="title">Print</p>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: 'NavDOck'
}
</script>