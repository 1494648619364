<template>
   <div class="forbidden">
      <h1>403</h1>
      <h3>Page Forbidden</h3>
   </div>
</template>

<script>
export default {
   name: 'Forbidden'
}
</script>

<style scoped>
   .forbidden h1 {
     font: 90px #f00 900;
   }
</style>